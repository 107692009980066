import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SecondaryPagesHero, Seo } from "../../../components/elements"

const Resources = ({ data, pageContext }) => {
  const page = data.datoCmsCancerResource
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel="resources"
        />
      </SecondaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", "full", 3 / 4]}>
                <Text
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsCancerResource {
      id
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`

export default Resources
